
:root {
  --dark-blue: #193972;
  --blue: #2061a9;
  --turquiose: #4192e0;
  --white: #fff;
  --light-gray: #f5f5f5f5;
  --teal: #50c7e0;
}

.App {
  font-family: Futura, sans-serif;
  color: var(--dark-blue);
  /* position: fixed; */
}

/* Dealership Toolkit */
.title1 {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}
.content-container {
  position: relative;
  z-index: 1;
  /* padding-bottom:10px; */
}

#LogoutButton {
position:absolute;
top: 10px;
right: 40px;
margin-left: auto;
}

#CoastC {
  margin: auto;
}

.coastLogo {
  width: fit-content;
  margin: auto;
  text-align: center;
}

div[data-amplify-router] {
  border-radius: 50px;
}

.welcome {
  margin: 0;
  color: var(--dark-blue);
}

button.amplify-button--primary {
  background: var(--dark-blue);
  text-transform: capitalize;
  height: 3.5em;
  border-radius: 40px;
}

button.amplify-button--primary:hover {
  background: var(--blue);
}

button.amplify-button--link {
  color: var(--dark-blue);
}

button.amplify-button--link:hover {
  background: inherit;
  color: var(--teal);
}

.amplify-field-group__control {
  border-radius: 20px;
}