#app {
  width: fit-content;
  margin: auto;
}

#responsive-table {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

td, th {
  padding: 10px;
}

td {
  text-align: right;
}

/* tr {
  justify-content: space-between;
} */

.cell-content {
  text-align: center;
}

.cell-content span {
  display: block; 
  margin-bottom: 5px; 
}

.EmailOverview {
  display: flex;
  justify-content: center;
  align-items: center;
}
