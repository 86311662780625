
.buttons {
    color: white;
    font-weight: bold;
    font-size: large;
    border: 2px solid var(--dark-blue);
    padding: 5px 10px;

    background-color: var(--dark-blue);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }
  

  .buttons:hover {
    background-color: var(--teal);
    color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

  }