.scheduler-container, .template, .product-image, .makeItSo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Make it so / Send Button */
.action-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #193972;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
    flex-direction: column;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
  .action-button:hover , .SubmitButton:hover {
    background-color: #4192e0;
  }

  .SubmitButton{
    padding: 5px 10px;
    font-size: 14px;
    background-color: #193972;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  /* Thumbnail */
  /* Display of Ad */
 .product-image {
  padding-top: 10px;
  
} 

 .disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
} 

/* input field for emailing */
/* Adjust as needed */
.content {
  margin-top: 10px; 
}

/* the thing below the banner img */
.scheduler{
    margin-top: 60px;
    padding-top: 10px;
}

.pickNames, .domains {
 display:inline-block;
 justify-content: center;
  padding: 3px;
  }

  #sentenceNInputs{
  position: relative;
  padding: 20px;
  }

  .cityPadding{
    padding-top: 2px;
  }

  .centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .action-button{
    margin-top: 3px;
  }
   
  #htmlFile {
    border: 1px solid #d3d3d3d3
  }

  #container4forms,.htmlLoader {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
 
} 

/* #viewWindow {
  width: 100%;
  height: 0px; 
  overflow: auto; 
} */
/* commented out because it was also on the admin page 
and makeing the Cards a weird size, didnt want that,
In the future may move that card to bottom or very top, idk */



  
  
