
#faqsPage {
    text-align: center;
    /* margin: 0 auto; */
    flex-direction: column;
    align-items: center;
    display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  
}

.faq-list {
  list-style: none;
  text-align: left;
}

.faq-QA {
  text-align: center;
  margin: 100px;
 
}
