#loginCardContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
} 

.login-card {
  background-color: white;
  padding: 10px;
  border: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a shadow for a modal effect */
  text-align: center;
  vertical-align: middle;
  z-index: 1; /* Ensure the form is on top of the background */
  width: 250px; 
  box-sizing: border-box;
  overflow: clip;
  

}

.login-form label {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: var(--dark-blue);
  color: white;
  border-radius: 10px;
  margin-top: 10px;
}

.login-form button:hover {
  background-color: var(--teal);
}

.login-card input {
  width: 90%;
}

#bgImg{
  background-size: auto;
  width: auto;
  height: auto; 
 z-index: -1; 
}

.img-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  text-align: center;
}

#CoastC{
  width: 200px;
  height: 200px;
}