
.rbc-calendar {
    font-family: Futura , sans-serif;
    background-color: #fff;
    border: 1px solid #f5f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: flex-start;  */
  padding: 20px;
  }
  
  .rbc-header {
    background-color: #193972;
    color: #fff;
  }
  
  .rbc-today {
    background-color: #f0f0f0;
  }
  
  .rbc-event {
    background-color: #50c7e0;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
  }
  
  /* Customize event styles */
  .rbc-event-label {
    font-weight: bold;
  }
  
  /* Customize date styles */
   .rbc-date-cell {
    font-weight: bold;
  }
  
  .rbc-month-row {
    min-width: 100%;
  }

  .moveDownElement{
    padding-top: 20px;
  }

  #notes{
    display: flex;
  flex-direction: column;
  align-items: center;
  }