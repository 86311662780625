
#page-wrap {
  text-align: center; 
  overflow: auto;
  position: relative;
}

.bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #193972;
    transition: color 0.2s;
  }
  
  .bm-item:hover {
    color: #50c7e0;
  }

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 10px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #193972;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #193972;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #193972;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 80px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: width 0.3s ease;
}

.sidebar.expanded {
  width: 200px;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  cursor: pointer;
}

.menu-text {
  display: none;
  margin-left: 30px;
}

.menu-toggle {
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 30px;
  cursor: pointer;
}

.sidebar.expanded .menu-text {
  display: inline;
}

.fa-icon {
  margin-right: 5px;
}
